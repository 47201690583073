import React, { useEffect, useState } from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import FooterNew from '../component/FooterNew/Footer'
import KeyFeatureText from '../component/KeyFeatureText/KeyFeatureText'
import { useNavigate, useParams } from 'react-router-dom'
import Contactform from '../component/Contact Form/Contactform'
import WhyChooseUs from '../component/WhyChooseUs/WhyChooseUs'
import { Helmet } from 'react-helmet'


function Immigrationinner() {
  const {id} = useParams()
  const [headingTitle , setHeadingTitle] = useState("")
  const [bannerImg , setBannerImg] = useState("/images/creatives/fedrel-hero-bg.jpg")
  const [heroPara , setHeroPara] = useState("Express Entry will manage applications for permanent residence under these federal economic immigration programs")
  const [metaTitle , setMetaTitle] = useState("");
  const [metaDesc , setMetaDesc] = useState('');
  
  const navigate = useNavigate()

  const idToInd = () => {
    let servId = 1;
    let bannerHeading= ''
    let heroText = ""
    let bgImg = ""
    let metaT = ""
    let metaD = ""
    switch (id) {
      case "refugee-cases":
        servId = 1;
        bannerHeading = "Refugee Cases";
        heroText = "";
        bgImg = '/images/creatives/refugee-hero-bg.jpg'
        metaT = "Canada Visa Refusal Assistance | Excel World Immigration"
        metaD = "Excel World Immigration provides expert assistance for visa refusal cases in Canada. Trust our guidance to turn refusals into approvals with confidence."
        break;
        case "refused-visa":
          servId = 2;
          bannerHeading = "Refused Visa";
          bgImg = '/images/creatives/refused-hero-bg.jpg'
          metaT = "Visa Refusal Consultancy Brampton | Excel World Immigration "
          metaD = "Excel World offers expert, compassionate support for navigating Brampton visa refusal consultancy. Trust us to help you with confidence."
          break;
          case "super-visa":
            servId = 3;
            bannerHeading = "Super Visa";
            bgImg = '/images/creatives/super-hero-bg.jpg'
            metaT = "Brampton Super Visa Consultants | Excel World Immigration"
            metaD = "Excel World Immigration offers expert assistance for Super Visa applications in Brampton. Reunite with family in Canada hassle-free with our dedicated support."
        break;
      // case "entrepreneur-program":
      //   servId = 4;
      //   bannerHeading = "Entrepreneur Program";
      //   bgImg = '/images/creatives/entrepreneur-program-hero-bg.jpg'
      //   metaT = ""
      //   metaD = ""
      //   break;
      // case "self-employed-program":
      //   servId = 5;
      //   bannerHeading = "Self Employed Program";
      //   bgImg = '/images/creatives/self-employed-program-hero-bg.jpg'
      //   metaT = ""
      //   metaD = ""
      //   break;
      // case "family-sponsorship":
        // servId = 6;
        // bannerHeading = "Family Sponsorship";
        // bgImg = '/images/creatives/family-sponsorship-hero-bg.jpg'
        // metaT = ""
        // metaD = ""
        // break;
      case "sponsoring-spouse":
        servId = 4;
        bannerHeading = "Sponsoring Spouse";
        bgImg = '/images/creatives/sponsoring-spouse-hero-bg.jpg'
        metaT = "Spousal Sponsorship Canada | Excel World Immigration"
        metaD = "Excel World Immigration offers expert spousal sponsorship services in Canada. Reunite with your spouse hassle-free with our personalized guidance and support."
        break;
      case "work-permit":
        servId = 5;
        bannerHeading = "Work Permit";
        bgImg = '/images/creatives/work-permit-hero-bg.jpg'
        metaT = "Ontario Work Permit | Online Work Visa | Excel World Immigration"
        metaD = "Excel World Immigration offers comprehensive support for Ontario work permit and online work visa applications. Trust our expertise for efficient processing and timely updates."
        break;
      case "visitors-visa":
        servId = 6;
        bannerHeading = "Visitors Visa";
        bgImg = '/images/creatives/visitors-visa-hero-bg.jpg'
        metaT = "Visitor Visa Brampton | Visa Application Process | Excel World Immigration"
        metaD = "Excel World Immigration offers expert support for obtaining a Visitor Visa in Brampton. Enjoy a stress-free process with our comprehensive assistance and high success rate."
        break;
      case "live-in-caregivers":
        servId = 7;
        bannerHeading = "Live-in Caregivers";
        bgImg = '/images/creatives/live-in-caregivers-hero-bg.jpg'
        metaT = "Caregiver Visa Canada | Brampton | Excel World Immigration"
        metaD = "Excel World Immigration provides comprehensive support for the caregiver visa program in Canada Ontario,,Brampton. Benefit from our expertise for successful caregiver placements."
        break;
      case "student-visa":
        servId = 8;
        bannerHeading = "Student Visa";
        bgImg = '/images/creatives/student-visa-hero-bg.jpg'
        metaT = "Canada Student Visa| Excel World Immigration"
        metaD = "Excel World Immigration offers expert support for Canada student visa applications in Ontario and Brampton. Benefit from our educational expertise and high success rate."
        break;
      case "pnp-program":
        servId = 9;
        bannerHeading = "PNP Program";
        bgImg = '/images/creatives/pnp-program-hero-bg.jpg'
        metaT = "Ontario Provincial Nominee Program (PNP) | Excel World Immigration"
        metaD = "Excel World Immigration offers expert assistance for the Ontario Provincial Nominee Program (PNP). Navigate the pathway to Canadian permanent residence with our comprehensive support."
        break;
      

      default:
        navigate("/immigration/federal-skilled-worker")
        break;
    }

    return {servId:servId , bannerHeading:bannerHeading , bgImg : bgImg , metaT: metaT , metaD : metaD, heroText:heroText};
  };

  
  
  const [ind,setInd] = useState(idToInd().servId)
  useEffect(()=>{
    setHeadingTitle(()=>idToInd().bannerHeading)
    setInd(()=>idToInd().servId);
    setBannerImg(()=>idToInd().bgImg);
    setHeroPara(()=>idToInd().heroText);
    setMetaTitle(()=>idToInd().metaT)
    setMetaDesc(()=>idToInd().metaD)
  } , [id])
    return (
        <div className="home-page">
          <Helmet>
          <title> 
          {metaTitle}
          </title>
          <meta 
          name='description' 
          content={metaDesc} 
          />
          
        </Helmet>
          
          <HeroBanner 
            subHeading=""
            Heading={headingTitle}
            spanHeading=''
            Paragraph={heroPara}
            nextLineSpanHeading=""
            bgImg={bannerImg}
            btn={false}
            isCheveron={false}
            btnRef="/"
            txtStart={false}
          />
          
    <div className='right-gradient'>
          <KeyFeatureText
            ind={ind}
          />
    
    </div>

    <WhyChooseUs/>

    <div className='right-upper-gradient'>

      <Contactform/>
    </div>
         
    
    
          <FooterNew/>
          </div>
      )
}

export default Immigrationinner