import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import SimpleTextCard from '../component/SimpleTextCard/SimpleTextCard'
import AdditionalResources from '../component/AdditionalResources/AdditionalResources'
import FooterNew from '../component/FooterNew/Footer'
import Contactform from '../component/Contact Form/Contactform'
import { Helmet } from 'react-helmet'

const cardData =[
    {
        title:"Complete your Express Entry profile",
        content:"Learn about what you will need to complete your Express Entry profile, and the steps that follow."
    },
    {
        title:"How Express Entry works",
        content:"Find out how the system works, how the Comprehensive Ranking System is used and how a candidate can be invited to apply for permanent residence."
    },
    {
        title:"Information for employers",
        content:"With Express Entry, you can fill labour market shortages permanently with skilled foreign nationals. Let us tell you how."
    },
    {
        title:"Choose Canada",
        content:"Discover how you and your family can benefit from Canada’s strong and stable economy and high standard of living."
    },
]

function ExpressEntry() {
  return (
    <div>
        <Helmet>
          <title> 
          Express Entry Canada | Immigration to Canada Programs
          </title>
          <meta 
          name='description' 
          content="Learn about Express Entry, Canada's pathway to permanent residence. Understand the process, eligibility, and how to apply under economic immigration programs."
          />
          
        </Helmet>
        <HeroBanner 
            subHeading=""
            Heading="Express Entry"
            nextLineSpanHeading=""
            Paragraph="As of January 2015, Citizenship and Immigration Canada (CIC) has a new stream for professionals called Express Entry to manage applications for permanent residence under certain economic immigration programs."
            btnText=""
            bgImg="/images/creatives/express-hero-bg.jpg"
            btn={false}
            btnRef="/"
            bgEffect={false}
            isCheveron={false}
        />
        <div className='left-gradient-bottom'>
            <div className='container standard-padding-space'>
                <div className='text-content text-center'>
                    <h3 className='body-heading '>Application </h3>
                    <h3 className='body-heading color-red'>Review & Submission </h3>
                    <p className='body-paragraph my-3'>The Express Entry system is the first step to immigrate to Canada under these programs. Potential candidates can complete an Express Entry profile at any time. Note that there is no deadline to complete a profile and there are no caps on the number of candidates that will be accepted to the pool. Anyone who is accepted into the Express Entry pool could get an Invitation to Apply for permanent residence. The selection draws from the Pool of Applications are done on regular basis (generally every month) candidates that make the list are invited to apply for permanent residence in Canada. Only top ranking candidates are invited to apply no matter when they were accepted into the pool. If you are invited to apply, you will have 60 days to submit a complete Application for Permanent Residence online.</p>
                </div>

                <SimpleTextCard
                    dataArr={cardData}
                />



            </div>
        </div>
                <AdditionalResources/>
                <div className='right-upper-gradient'>

                <Contactform/>
                </div>
   


                <FooterNew/>
    </div>
  )
}

export default ExpressEntry